$the-custom-palette-primary: (
  50: #e1ebf4,
  100: #b5cce3,
  200: #83abd0,
  300: #5189bd,
  400: #2c6fae,
  500: #0756a0,
  600: #064f98,
  700: #05458e,
  800: #043c84,
  900: #022b73,
  A100: #a1bdff,
  A200: #6e98ff,
  A400: #3b74ff,
  A700: #2262ff,
  contrast: (
    50: #181c32,
    100: #181c32,
    200: #181c32,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #181c32,
    A200: #181c32,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

$the-custom-palette-accent: (
  50: #eff8e9,
  100: #d6ecc8,
  200: #bbe0a3,
  300: #a0d47e,
  400: #8bca62,
  500: #77c146,
  600: #6fbb3f,
  700: #64b337,
  800: #5aab2f,
  900: #479e20,
  A100: #e4ffd9,
  A200: #bfffa6,
  A400: #9aff73,
  A700: #88ff59,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #ffffff,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$table-odd-background: #F6F5F9;
$search-box-background: #f3f6f9;
$search-box-border-color: #B5B5C3;
$svg-icon-color:#757575;
$font-color:#181C32;

@mixin major-background {
    background-color: #ebedf0;
}
@mixin major-box($rounded, $common: false) {
    box-shadow: 0 0 16px #0000000F;
    background-color: #ffffff;
    @if ($rounded) {
        border-radius: 6px;
    }
    z-index: 1;
    @if ($common) {
      padding-bottom: 24px;
    }
}
@mixin menu-box($rounded: true) {
    box-shadow: 0 0 16px #0000000F;
    background-color: #ffffff;
    color: $font-color;
    @if ($rounded) {
      border-radius: 6px;
    }
    z-index: 1;
    width: 200px;
    border: 1px solid $search-box-border-color;
    button[role="menuitem"] {
      width: 100%;
      text-align: left;
      justify-content: start;
      color: $font-color;
      padding: 24px 16px;
    }
    button.use-color-icon mat-icon {
      color: $svg-icon-color;
    }
}