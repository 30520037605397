
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@use 'src/sass/flex' as flex;
@use 'src/sass/palettes' as cpal;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$glowfy-cloud-ui-primary: mat.define-palette(cpal.$the-custom-palette-primary);
$glowfy-cloud-ui-accent: mat.define-palette(cpal.$the-custom-palette-accent);

// The warn palette is optional (defaults to red).
$glowfy-cloud-ui-warn: mat.define-palette(mat.$red-palette);

$glowfy-cloud-typography: mat.define-typography-config(
  $font-family : (Poppins, "Helvetica Neue", sans-serif),
  $body-1 : mat.define-typography-level(14px, 24px, 400)
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$glowfy-cloud-ui-theme: mat.define-light-theme((
  color: (
    primary: $glowfy-cloud-ui-primary,
    accent: $glowfy-cloud-ui-accent,
    warn: $glowfy-cloud-ui-warn,
  ),
  typography: $glowfy-cloud-typography,
  density: 0,
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($glowfy-cloud-ui-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Poppins, "Helvetica Neue", sans-serif; font-size: 14px; }
body input, body button, body select, body textarea { font-family: Poppins, "Helvetica Neue", sans-serif; }

$color-accent: mat.get-color-from-palette($glowfy-cloud-ui-accent, 500);
$color-primary: mat.get-color-from-palette($glowfy-cloud-ui-primary, 500);
$color-warn: mat.get-color-from-palette($glowfy-cloud-ui-warn, 500);

$contrast-accent: mat.get-color-from-palette($glowfy-cloud-ui-accent, 500-contrast);
$contrast-primary: mat.get-color-from-palette($glowfy-cloud-ui-primary, 500-contrast);
$contrast-warn: mat.get-color-from-palette($glowfy-cloud-ui-warn, 500-contrast);

$glowfy-border-color: #e0e0e0;

.use-common {
  button, a {
    letter-spacing: normal;
    &[mat-raised-button][color]:not(:disabled), &[mat-flat-button][color]:not(:disabled) {
      color: $contrast-accent;
    }
  }
}

.custom {
  &.inverted {
    &.accent {
      background: $color-accent;
      color: $contrast-accent;
    }
    &.primary {
      background: $color-primary;
      color: $contrast-primary;
    }
    &.warn {
      background: $color-warn;
      color: $contrast-warn;
    }

    &.accent.hover:hover, &.accent button {
      background: $contrast-accent;
      color: $color-accent;
    }
    &.primary.hover:hover, &.primary button {
      background: $contrast-primary;
      color: $color-primary;
    }
    &.warn.hover:hover, &.warn button {
      background: $contrast-warn;
      color: $color-warn;
    }
  }

  &.contrast {
    &.accent {
      background: $contrast-accent;
      color: $color-accent;
    }
    &.primary {
      background: $contrast-primary;
      color: $color-primary;
    }
    &.warn {
      background: $contrast-warn;
      color: $color-warn;
    }
  }

  &.color {
    &.accent {
      color: $color-accent;
    }
    &.primary {
      color: $color-primary;
    }
    &.warn {
      color: $color-warn;
    }
  }
}

.plain-link, .plain-link:visited, .plain-link:hover, .plain-link:active {
  text-decoration: none;
  color: currentColor;
}

body *:not([class^="mat-"]) {
   box-sizing: border-box;
}

@include flex.b-flex-utilities();
@include flex.b-body-loader();

h3 { font-size: 1.2em; margin-top: 0; color: mat.get-color-from-palette($glowfy-cloud-ui-primary, 50-contrast); }

.the-table-section {
  overflow-x: auto;
  width: 100%;
  .the-table-cover {
    min-width: 760px;
    width: 100%;
    table {
      width: 100%;
      .use-align-center { text-align: center; }
      .the-table-header th {
        line-height: 1em;
        font-weight: bold;
        color: mat.get-color-from-palette($glowfy-cloud-ui-primary, 50-contrast);
        border-top: 1px solid $glowfy-border-color;
        &:last-child:not(.use-align-no), &.use-align-center { text-align: center; }
      }
      .the-table-row:nth-child(2n + 1) td {
        background: cpal.$table-odd-background;
      }
      .the-table-row:last-child td {
        border-bottom: 1px solid $glowfy-border-color;
      }
    }
  }
}
.the-search-box input[type] { background: transparent; }
.use-nowrap { white-space: nowrap; }
// add-edit popup styles
section.the-edit-content {
  overflow: auto;
  padding: 8px 8px;
  > div {
    padding: 8px 16px 8px 16px;
  }
  mat-form-field {
    width: 100%;
  }
}
section.the-edit-section {
  &.ec-override {
    padding: 24px;
  }
  &.ec-action {
    border-top: 1px solid $glowfy-border-color;
    margin-top: 0;
  }
  &.ec-header {
    border-bottom: 1px solid $glowfy-border-color;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
// add-edit popup styles end

.use-outline-icon mat-icon, mat-icon.use-outline-icon {
  &:not(.use-solid-icon) {
    font-family: 'Material Icons Outlined';
  }
}
.use-color-icon.custom mat-icon, mat-icon.use-color-icon.custom {
  color: cpal.$svg-icon-color;
}

@include flex.b-snackbar($colors : (success: $color-accent, warn: $color-warn))