@use "./breakpoints" as b;
@use "./palettes" as p;

$flexutil: (
  "disp": (
    responsive: true,
    property: display,
    values: (
      flex: flex,
      inline-flex: inline-flex,
      block: block,
      inline: inline,
      none: none,
    ),
  ),
  "flex": (
    responsive: true,
    property: flex,
    values: (
      fill: 1 1 auto,
    ),
  ),
  "flex-direction": (
    responsive: true,
    property: flex-direction,
    class: flex,
    values: (
      row: row,
      column: column,
      row-reverse: row-reverse,
      column-reverse: column-reverse,
    ),
  ),
  "flex-grow": (
    responsive: true,
    property: flex-grow,
    class: flex,
    values: (
      grow-0: 0,
      grow-1: 1,
    ),
  ),
  "flex-shrink": (
    responsive: true,
    property: flex-shrink,
    class: flex,
    values: (
      shrink-0: 0,
      shrink-1: 1,
    ),
  ),
  "flex-wrap": (
    responsive: true,
    property: flex-wrap,
    class: flex,
    values: (
      wrap: wrap,
      nowrap: nowrap,
      wrap-reverse: wrap-reverse,
    ),
  ),
  "gap": (
    responsive: true,
    property: gap,
    class: gap,
    values: (
      1: 4px,
      2: 8px,
      3: 12px,
      4: 16px,
    ),
  ),
  "justify-content": (
    responsive: true,
    property: justify-content,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      between: space-between,
      around: space-around,
      evenly: space-evenly,
    ),
  ),
  "align-items": (
    responsive: true,
    property: align-items,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      baseline: baseline,
      stretch: stretch,
    ),
  ),
  "align-content": (
    responsive: true,
    property: align-content,
    values: (
      start: flex-start,
      end: flex-end,
      center: center,
      between: space-between,
      around: space-around,
      stretch: stretch,
    ),
  ),
  "align-self": (
    responsive: true,
    property: align-self,
    values: (
      auto: auto,
      start: flex-start,
      end: flex-end,
      center: center,
      baseline: baseline,
      stretch: stretch,
    ),
  ),
  "order": (
    responsive: true,
    property: order,
    values: (
      first: -1,
      0: 0,
      1: 1,
      2: 2,
      3: 3,
      4: 4,
      5: 5,
      last: 6,
    ),
  ),
  "margin": (
    responsive: true,
    property: margin,
    class: margin,
    values: (
      0: 0px,
      1: 4px,
      2: 8px,
      3: 12px,
      4: 16px,
    ),
  ),
  "padding": (
    responsive: true,
    property: padding,
    class: padding,
    values: (
      0: 0px,
      1: 4px,
      2: 8px,
      3: 12px,
      4: 16px,
    ),
  ),
  "border": (
    responsive: true,
    property: border,
    class: border,
    values: (
      0: none,
      1: 1px solid #ccc,
    ),
  ),
  "radius": (
    responsive: false,
    property: border-radius,
    class: radius,
    values: (
      0: 0,
      1: 4px,
      circle: 100%,
    ),
  ),
  "width": (
    responsive: true,
    property: width,
    class: width,
    values: (
      100: 100%,
      auto: auto,
    ),
  ),
) !default;

@mixin b-flex-utilities() {
  $g-breakpoints: b.get-breakpoints();
  @each $breakpoint in map-keys($g-breakpoints) {
    // Generate media query if needed
    @include b.media-breakpoint-up($breakpoint) {
      $infix: b.breakpoint-infix($breakpoint, $g-breakpoints);

      // Loop over each utility property
      @each $key, $utility in $flexutil {
        $class: if(
          map-has-key($utility, class),
          map-get($utility, class),
          $key
        );
        $property: if(
          map-has-key($utility, property),
          map-get($utility, property),
          $key
        );
        $values: map-get($utility, values);
        $responsive: map-get($utility, responsive);

        @each $name, $value in $values {
          @if $infix == "" {
            .b-#{$class}-#{$name} {
              #{$property}: #{$value};
            }
          } @else if $responsive == true {
            .b-#{$class}#{$infix}-#{$name} {
              #{$property}: #{$value};
            }
          }
        }
      }
    }
  }
}

@mixin b-body-loader() {
  @keyframes bodyloader {
    from { right: calc(100% + 100px); }
    to { right: calc(0% - 100px); }
  }
  body.the-ajax-progress::after {
    position: fixed;
    display: block;
    content: '';
    height: 4px;
    background-color: map-get($map: p.$the-custom-palette-accent, $key: 900);
    width: 100px;
    z-index: 10;
    right: 0;
    top: 0;
    animation-name: bodyloader;
    animation-duration: 3s;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
  }
}

@mixin b-snackbar($colors : (success: green, warn: red )) {
  .custom {
    &.success .mdc-snackbar__surface, &.success .mdc-snackbar__surface button:not(:disabled) {
      color: white;
      background-color: map-get($colors, success);
    }
    &.warn .mdc-snackbar__surface, &.warn .mdc-snackbar__surface button:not(:disabled) {
      color: white;
      background-color: map-get($colors, warn);
    }
  }
}
// always use @use. Included in styles no need to include it in component styles
